.mainDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}


.icons {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: black solid 1px;
  padding: 3%;
}

a {
  color: black;
  font-family: monospace;
  color: var(--color-dark);
  font-size: 20px;
}

.generalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 0%;
  width: 100%;
  height: 100%;
}

.meForm {
  display: flex;
}


