:root {
  --color-dark: #161616;
  --color-ocean: #416dea;
  --color-grass: #3dd28d;
  --color-snow: #ffffff;
  --color-salmon: #f32c52;
  --color-sun: #feee7d;
  --color-alge: #7999a9;
  --color-flower: #353866;
  --color-smoke: #e4e4e4;

  --borderRadius: 36px;

  --font-face: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* // body styles */

body {
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;

  background: whitesmoke;
  font-family: monospace;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

/* // text styles */

h1 {
  text-align: center;
  font-family: monospace;
  font-size: 32px;
  font-weight: 700;
}

p {
  max-width: 460px;
  margin: 0 auto;

  transition: color 300ms linear;
  text-align: center;

  color: var(--color-dark);

  font-weight: 400;
  line-height: 1.5;
}

.homePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.typing-hi {
  width: 3ch;
  animation: typing .5s steps(3), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 5em;
}

.typing-im {
  width: 9ch;
  animation: typing 1.5s steps(9), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 5em;
}

.typing-stack {
  width: 21ch;
  animation: typing 2.5s steps(21), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 5em;
}

.typing-ola {
  width: 4ch;
  animation: typing .5s steps(4), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 5em;
}

.typing-eu {
  width: 14ch;
  animation: typing 1.5s steps(14), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 5em;
}

.typing-dev {
  width: 25ch;
  animation: typing 2.5s steps(25), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 5em;
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.photome {
  width: 25%;
  border-radius: 25%;
}

.title {
  display: flex;
}

.aboutMe {
  /* animation-play-state: 4s; */
  padding: 1%;
  padding-top: 3%;
  display: flex;
  justify-content: space-evenly;
  /* border-bottom: black solid 1px; */
}

.textAbout {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

path {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
  /* Create a stroke-dasharray that's the length of the path, meaning that there will be a gap between dashes equal to the length of the path */
  /* If you don't know the length of your path, this will help: https://codepen.io/gabriellewee/full/EZPYmw/ */
  stroke-dasharray: 400;
  /* Offset the stroke dashes the length of the path so that the stroke is invisible */
  stroke-dashoffset: 400;
  animation: stroke-anim 1.75s ease-in-out alternate infinite;
}

/* Animate the stroke offset to 0 so the line appears to be drawn in */
@keyframes stroke-anim {
  to { stroke-dashoffset: 0.5; }
}

/* Fun trick to center the SVG in the viewport */
/* svg { */
  /* position: sticky; */
  /* top: 0; right: 0; bottom: 0; left: 0; */
  /* margin: auto; */
/* } */