.header {
  background-color: whitesmoke;
  top: 0%;
  width: 100%;
  display: flex;
  border-bottom: black solid 1px;
  /* padding: 10px; */
}

.size {
  width: 15%;
}

.buttonsHeader {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  left: 0;
  margin-bottom: 1%;
  margin-top: 1%;
}

.languageButton {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  margin-top: 1%;
  margin-bottom: 1%;
}


